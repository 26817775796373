import { useSyncExternalStore } from "react";
import { useTruthyEffect } from "./useTruthyEffect";
const useVisibilityChangeSubscribe = (callback)=>{
    document.addEventListener("visibilitychange", callback);
    return ()=>{
        document.removeEventListener("visibilitychange", callback);
    };
};
const getVisibilityChangeSnapshot = ()=>{
    return document.visibilityState;
};
const getVisibilityChangeServerSnapshot = ()=>"visible";
export function useVisibilityChange() {
    const visibilityState = useSyncExternalStore(useVisibilityChangeSubscribe, getVisibilityChangeSnapshot, getVisibilityChangeServerSnapshot);
    return visibilityState === "visible";
}
export function useVisibleEffect(handler) {
    const visible = useVisibilityChange();
    useTruthyEffect(visible, ()=>handler());
}
export function useInvisibleEffect(handler) {
    const visible = useVisibilityChange();
    useTruthyEffect(!visible, ()=>handler());
}
