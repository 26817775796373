/* eslint-disable prefer-spread */ /* eslint-disable @typescript-eslint/no-explicit-any */ /* eslint-disable @typescript-eslint/ban-ts-comment */ /// <reference lib="es2019.array" />
/// <reference lib="es2019.object" />
/// <reference lib="es2022.array" />
/// <reference lib="es2023.array" />
/// <reference lib="dom" />
/// <reference lib="dom.iterable" />
/// <reference lib="dom.asynciterable" />
var // @ts-ignore
_Symbol, // @ts-ignore
_Symbol1;
function polyfill(target, name, value) {
    if (!(name in target)) {
        Object.defineProperty(target, name, {
            configurable: true,
            value
        });
    }
}
const copyarray = (arr)=>Array.prototype.slice.call(arr);
polyfill(Array.prototype, "flat", function flat(depth = 1) {
    // @ts-ignore
    return depth ? // @ts-ignore
    Array.prototype.reduce.call(this, (acc, cur)=>{
        if (Array.isArray(cur)) {
            acc.push.apply(acc, flat.call(cur, depth - 1));
        } else {
            acc.push(cur);
        }
        return acc;
    }) : Array.prototype.slice.call(this);
});
polyfill(Array.prototype, "flatMap", function() {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    return Array.prototype.map.apply(this, arguments).flat();
});
polyfill(Array.prototype, "at", function(index) {
    const len = this.length;
    const idx = +index;
    const k = idx >= 0 ? idx : len + idx;
    if (k < 0 || k >= len) return undefined;
    return this[k];
});
polyfill(Array.prototype, "findLast", function(predicate, thisArg = this) {
    const arr = copyarray(this);
    const len = arr.length >>> 0;
    for(let i = len - 1; i >= 0; i--){
        if (predicate.call(thisArg, arr[i], i, arr)) {
            return arr[i];
        }
    }
    return undefined;
});
polyfill(Array.prototype, "findLastIndex", function(predicate, thisArg = this) {
    const arr = copyarray(this);
    const len = arr.length >>> 0;
    for(let i = len - 1; i >= 0; i--){
        if (predicate.call(thisArg, arr[i], i, arr)) {
            return i;
        }
    }
    return -1;
});
polyfill(Array.prototype, "with", function(index, value) {
    const copied = copyarray(this);
    copied[index] = value;
    return copied;
});
polyfill(Array.prototype, "toSorted", function(compareFn) {
    return copyarray(this).sort(compareFn);
});
polyfill(Array.prototype, "toSpliced", function(start, deleteCount) {
    return copyarray(this).splice(start, deleteCount);
});
polyfill(Array.prototype, "toReversed", function() {
    return copyarray(this).reverse();
});
polyfill(String.prototype, "at", function(index) {
    const len = this.length;
    const idx = +index;
    const k = idx >= 0 ? idx : len + idx;
    if (k < 0 || k >= len) return undefined;
    return this[k];
});
const emptyStringRegex = /(?:)/g;
polyfill(String.prototype, "replaceAll", function(searchValue, replaceValue) {
    if (typeof this !== "string") {
        throw new TypeError("Expected a string");
    }
    if (searchValue instanceof RegExp) {
        const { global: globalFlag } = searchValue;
        if (!globalFlag) {
            throw new TypeError("`String.prototype.replaceAll` ponyfill called with a non-global RegExp argument");
        }
        return this.replace(searchValue, replaceValue);
    }
    if (searchValue === "") {
        const value = this.replace(emptyStringRegex, replaceValue);
        emptyStringRegex.lastIndex = 0;
        return value;
    }
    const stringToFind = typeof searchValue !== "string" ? String(searchValue) : searchValue;
    return this.replace(new RegExp(stringToFind.replace(/[|\\{}()[\]^$+*?.]/g, "\\$&").replace(/-/g, "\\x2d"), "g"), replaceValue);
});
polyfill(Object, "fromEntries", function(iterable) {
    return [
        ...iterable
    ].reduce((obj, [key, val])=>{
        obj[key] = val;
        return obj;
    }, {});
});
polyfill(URL.prototype, "toJSON", function() {
    return this.toString();
});
polyfill(URL, "canParse", (url)=>{
    try {
        new URL(url);
        return true;
    } catch (e) {
        return false;
    }
});
polyfill(Object, "groupBy", (items, callbackfn)=>{
    const obj = Object.create(null);
    for(let i = 0; i < items.length; i++){
        const item = items[i];
        const key = callbackfn(item, i);
        obj[key] = [
            ...obj[key] || [],
            item
        ];
    }
    return obj;
});
polyfill(Promise, "withResolvers", function withResolvers() {
    const result = Object.create(null);
    result.promise = new Promise((resolve, reject)=>{
        result.resolve = resolve;
        result.reject = reject;
    });
    return result;
});
var _dispose;
(_dispose = (_Symbol = Symbol).dispose) != null ? _dispose : _Symbol.dispose = Symbol("Symbol.dispose");
var _asyncDispose;
(_asyncDispose = (_Symbol1 = Symbol).asyncDispose) != null ? _asyncDispose : _Symbol1.asyncDispose = Symbol("Symbol.asyncDispose");
if (typeof window !== "undefined") {
    window.addEventListener("native.showkeyboard", ()=>{
        const active = document.activeElement;
        if (active && (active instanceof HTMLInputElement || active instanceof HTMLTextAreaElement)) {
            setTimeout(()=>{
                active.blur();
                active.focus();
            }, 200);
        }
    });
    polyfill(CanvasRenderingContext2D.prototype, "roundRect", roundRect);
    polyfill(Path2D.prototype, "roundRect", roundRect);
    function roundRect(x, y, w, h, radii_) {
        if (![
            x,
            y,
            w,
            h
        ].every((input)=>Number.isFinite(input))) {
            return;
        }
        const radii = parseRadiiArgument(radii_);
        let upperLeft;
        let upperRight;
        let lowerRight;
        let lowerLeft;
        if (radii.length === 4) {
            upperLeft = toCornerPoint(radii[0]);
            upperRight = toCornerPoint(radii[1]);
            lowerRight = toCornerPoint(radii[2]);
            lowerLeft = toCornerPoint(radii[3]);
        } else if (radii.length === 3) {
            upperLeft = toCornerPoint(radii[0]);
            upperRight = toCornerPoint(radii[1]);
            lowerLeft = toCornerPoint(radii[1]);
            lowerRight = toCornerPoint(radii[2]);
        } else if (radii.length === 2) {
            upperLeft = toCornerPoint(radii[0]);
            lowerRight = toCornerPoint(radii[0]);
            upperRight = toCornerPoint(radii[1]);
            lowerLeft = toCornerPoint(radii[1]);
        } else if (radii.length === 1) {
            upperLeft = toCornerPoint(radii[0]);
            upperRight = toCornerPoint(radii[0]);
            lowerRight = toCornerPoint(radii[0]);
            lowerLeft = toCornerPoint(radii[0]);
        } else {
            throw new RangeError(`${getErrorMessageHeader(this)} ${radii.length} is not a valid size for radii sequence.`);
        }
        const corners = [
            upperLeft,
            upperRight,
            lowerRight,
            lowerLeft
        ];
        const negativeCorner = corners.find(({ x, y })=>x < 0 || y < 0);
        const negativeValue = negativeCorner && (negativeCorner == null ? void 0 : negativeCorner.x) < 0 ? negativeCorner.x : negativeCorner == null ? void 0 : negativeCorner.y;
        if (corners.some(({ x, y })=>!Number.isFinite(x) || !Number.isFinite(y))) {
            return;
        }
        if (negativeCorner) {
            throw new RangeError(`${getErrorMessageHeader(this)} Radius value ${negativeCorner} is negative.`);
        }
        fixOverlappingCorners(corners);
        if (w < 0 && h < 0) {
            this.moveTo(x - upperLeft.x, y);
            this.ellipse(x + w + upperRight.x, y - upperRight.y, upperRight.x, upperRight.y, 0, -Math.PI * 1.5, -Math.PI);
            this.ellipse(x + w + lowerRight.x, y + h + lowerRight.y, lowerRight.x, lowerRight.y, 0, -Math.PI, -Math.PI / 2);
            this.ellipse(x - lowerLeft.x, y + h + lowerLeft.y, lowerLeft.x, lowerLeft.y, 0, -Math.PI / 2, 0);
            this.ellipse(x - upperLeft.x, y - upperLeft.y, upperLeft.x, upperLeft.y, 0, 0, -Math.PI / 2);
        } else if (w < 0) {
            this.moveTo(x - upperLeft.x, y);
            this.ellipse(x + w + upperRight.x, y + upperRight.y, upperRight.x, upperRight.y, 0, -Math.PI / 2, -Math.PI, true);
            this.ellipse(x + w + lowerRight.x, y + h - lowerRight.y, lowerRight.x, lowerRight.y, 0, -Math.PI, -Math.PI * 1.5, true);
            this.ellipse(x - lowerLeft.x, y + h - lowerLeft.y, lowerLeft.x, lowerLeft.y, 0, Math.PI / 2, 0, true);
            this.ellipse(x - upperLeft.x, y + upperLeft.y, upperLeft.x, upperLeft.y, 0, 0, -Math.PI / 2, true);
        } else if (h < 0) {
            this.moveTo(x + upperLeft.x, y);
            this.ellipse(x + w - upperRight.x, y - upperRight.y, upperRight.x, upperRight.y, 0, Math.PI / 2, 0, true);
            this.ellipse(x + w - lowerRight.x, y + h + lowerRight.y, lowerRight.x, lowerRight.y, 0, 0, -Math.PI / 2, true);
            this.ellipse(x + lowerLeft.x, y + h + lowerLeft.y, lowerLeft.x, lowerLeft.y, 0, -Math.PI / 2, -Math.PI, true);
            this.ellipse(x + upperLeft.x, y - upperLeft.y, upperLeft.x, upperLeft.y, 0, -Math.PI, -Math.PI * 1.5, true);
        } else {
            this.moveTo(x + upperLeft.x, y);
            this.ellipse(x + w - upperRight.x, y + upperRight.y, upperRight.x, upperRight.y, 0, -Math.PI / 2, 0);
            this.ellipse(x + w - lowerRight.x, y + h - lowerRight.y, lowerRight.x, lowerRight.y, 0, 0, Math.PI / 2);
            this.ellipse(x + lowerLeft.x, y + h - lowerLeft.y, lowerLeft.x, lowerLeft.y, 0, Math.PI / 2, Math.PI);
            this.ellipse(x + upperLeft.x, y + upperLeft.y, upperLeft.x, upperLeft.y, 0, Math.PI, Math.PI * 1.5);
        }
        this.closePath();
        this.moveTo(x, y);
        function toDOMPointInit(value) {
            const { x, y, z, w } = value;
            return {
                x,
                y,
                z,
                w
            };
        }
        function parseRadiiArgument(value) {
            // https://webidl.spec.whatwg.org/#es-union
            // with 'optional (unrestricted double or DOMPointInit
            //   or sequence<(unrestricted double or DOMPointInit)>) radii = 0'
            if (typeof value === "undefined" || value === null) {
                return [
                    0
                ];
            }
            if (typeof value === "function") {
                return [
                    NaN
                ];
            }
            if (typeof value === "object") {
                if (Symbol.iterator in value && typeof value[Symbol.iterator] === "function") {
                    return [
                        ...value
                    ].map((elem)=>{
                        // https://webidl.spec.whatwg.org/#es-union
                        // with '(unrestricted double or DOMPointInit)'
                        const elemType = typeof elem;
                        if (elemType === "undefined" || elem === null) {
                            return 0;
                        }
                        if (elemType === "function") {
                            return NaN;
                        }
                        if (elemType === "object") {
                            return toDOMPointInit(elem);
                        }
                        return toUnrestrictedNumber(elem);
                    });
                }
                return [
                    toDOMPointInit(value)
                ];
            }
            return [
                toUnrestrictedNumber(value)
            ];
        }
        function toUnrestrictedNumber(value) {
            return +value;
        }
        function toCornerPoint(value) {
            const asNumber = toUnrestrictedNumber(value);
            if (Number.isFinite(asNumber)) {
                return {
                    x: asNumber,
                    y: asNumber
                };
            }
            if (Object(value) === value) {
                var _value_x, _value_y;
                return {
                    x: toUnrestrictedNumber((_value_x = value.x) != null ? _value_x : 0),
                    y: toUnrestrictedNumber((_value_y = value.y) != null ? _value_y : 0)
                };
            }
            return {
                x: NaN,
                y: NaN
            };
        }
        function fixOverlappingCorners(corners) {
            const [upperLeft, upperRight, lowerRight, lowerLeft] = corners;
            const factors = [
                Math.abs(w) / (upperLeft.x + upperRight.x),
                Math.abs(h) / (upperRight.y + lowerRight.y),
                Math.abs(w) / (lowerRight.x + lowerLeft.x),
                Math.abs(h) / (upperLeft.y + lowerLeft.y)
            ];
            const minFactor = Math.min(...factors);
            if (minFactor <= 1) {
                for (const radii of corners){
                    radii.x *= minFactor;
                    radii.y *= minFactor;
                }
            }
        }
    }
    function getErrorMessageHeader(instance) {
        return `Failed to execute 'roundRect' on '${getConstructorName(instance)}':`;
    }
    function getConstructorName(instance) {
        var _globalThis, _globalThis1;
        return Object(instance) === instance && instance instanceof Path2D ? "Path2D" : instance instanceof ((_globalThis = globalThis) == null ? void 0 : _globalThis.CanvasRenderingContext2D) ? "CanvasRenderingContext2D" : instance instanceof ((_globalThis1 = globalThis) == null ? void 0 : _globalThis1.OffscreenCanvasRenderingContext2D) ? "OffscreenCanvasRenderingContext2D" : (instance == null ? void 0 : instance.constructor.name) || instance;
    }
}
export { };
