function _extends() {
    _extends = Object.assign || function(target) {
        for(var i = 1; i < arguments.length; i++){
            var source = arguments[i];
            for(var key in source){
                if (Object.prototype.hasOwnProperty.call(source, key)) {
                    target[key] = source[key];
                }
            }
        }
        return target;
    };
    return _extends.apply(this, arguments);
}
import "./polyfill";
export function createPromiseCache(resolve) {
    const cache = new Map();
    function fn(input) {
        let promise = cache.get(input);
        if (!promise) {
            promise = createPromiseWithState();
            promise.resolve(resolve(input));
            cache.set(input, promise);
        }
        return promise;
    }
    function async(input) {
        const promise = fn(input);
        return promise.state === "fulfilled" ? promise.value : null;
    }
    function clear(input) {
        cache.delete(input);
    }
    return Object.assign(fn, {
        async: Object.assign(async, {
            clear
        }),
        clear
    });
}
export function createPromiseCacheWithResolver(resolver) {
    const cache = new Map();
    function fn(input) {
        let promise = cache.get(input);
        if (!promise) {
            promise = createPromiseWithState();
            resolver(input, promise);
            cache.set(input, promise);
        }
        return promise;
    }
    return Object.assign(fn, {
        get async () {
            return (input)=>{
                const promise = fn(input);
                return promise.state === "fulfilled" ? promise.value : null;
            };
        }
    });
}
export function createPromiseWithState() {
    const resolvers = Promise.withResolvers();
    const ret = _extends({}, resolvers, {
        state: "pending"
    });
    resolvers.promise.then((value)=>{
        ret.state = "fulfilled";
        ret.value = value;
    }, (error)=>{
        ret.state = "rejected";
        ret.error = error;
    });
    return ret;
}
