import { useCallback, useInsertionEffect, useRef } from "react";
export function useEventHandler(callback) {
    const latestRef = useRef(shouldNotBeInvokedBeforeMount);
    const lockRef = useRef(false);
    useInsertionEffect(()=>{
        latestRef.current = callback;
    }, [
        callback
    ]);
    return useCallback((...args)=>{
        if (lockRef.current) return null;
        const fn = latestRef.current;
        const ret = fn(...args);
        if (ret instanceof Promise) {
            lockRef.current = true;
            ret.finally(()=>lockRef.current = false);
            return ret;
        }
        return ret;
    }, []);
}
function shouldNotBeInvokedBeforeMount() {
    throw new Error("foxact: the stablized handler cannot be invoked before the component has mounted.");
}
